import type { TimeFilterConfiguration } from '@grandvisionhq/appointments'

const config: TimeFilterConfiguration = [
  {
    name: 'option1',
    from: '09:00',
    until: '12:00',
  },
  {
    name: 'option2',
    from: '12:00',
    until: '17:00',
  },
  {
    name: 'option3',
    from: '17:00',
    until: '21:00',
  },
]

export const getTimeFiltersConfig = () => config
