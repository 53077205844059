export const promoteInstallmentsConfiguration = {
  minimumPrice: 0,
  numberOfInstallments: 3,
  enabledProductTypes: ['sunglasses', 'contact-lenses', 'solutions'],
}

export const graphPromoteInstallmentsConfiguration = {
  minimumPrice: 0,
  numberOfInstallments: 3,
}
