import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    '14750f00-7900-417c-a242-4b2c955c6d9f': {
      // online-store TEST
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    'c4511464-f18a-4bab-913e-fe5fa288cef4': {
      // external-warehouse TEST
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
    '930b6499-c8e8-4716-9757-53b9b5d63223': {
      // online-store ACCT
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    'a7099325-e85c-49e6-bdc5-84f194671af1': {
      // external-warehouse ACCT
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
    'bf217b74-cd89-4623-8dab-1f9a35957505': {
      // online-store PROD
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    'e3e311fe-8229-4235-9786-3d2be757ee7f': {
      // external-warehouse PROD
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '14+',
  },
})
