import getConfig from 'next/config'

import { Types as AuthTypes } from '@grandvisionhq/auth'
import type { Context as CartContext } from '@grandvisionhq/carts-v2'
import type { CheckoutSettings, Routes, ShippingMethodsLogos } from '@grandvisionhq/checkout-v2'

interface SettingsInput {
  cart: CartContext.CartContextModel['cart']
  shippingMethodsLogos?: ShippingMethodsLogos
}

const { publicRuntimeConfig } = getConfig()
const { orderTimeOut } = publicRuntimeConfig.checkout

export const basePath = '/checkout'
export const routes: Routes = {
  shipping: {
    label: 'Leverans',
    path: `${basePath}/leverans`,
  },
  overview: {
    label: 'Översikt',
    path: `${basePath}/oversikt`,
  },
  payment: {
    label: 'Betalning',
    path: `${basePath}/betalning`,
  },
}

export const getCheckoutConfig = ({
  cart,
  shippingMethodsLogos,
}: SettingsInput): CheckoutSettings => {
  const paymentMethodLogos = {
    SWISH: {
      url: 'https://cdn.grandvision.io/binaries/_ht_1555402648638/content/gallery/se-synoptik/logos/payment-options/swish.svg',
    },
    swish: {
      url: 'https://cdn.grandvision.io/binaries/_ht_1555402648638/content/gallery/se-synoptik/logos/payment-options/swish.svg',
    },
  }

  const optIns: AuthTypes.OptInItem[] = [
    {
      titleKey: 'account.optIn.marketingEmails',
      value: 'marketingEmailsOptIn',
    },
  ]

  return {
    account: {
      implicitAccountCreation: false,
      createAccountRequired: false,
    },
    consent: {
      implicitTermsAndConditions: true,
      optIns,
    },
    shipping: {
      explicitShippingMethod: true,
    },
    shippingDetails: {
      loadingFinished: Boolean(cart) || cart === null,
      ...(cart?.billingAddress ? { billingAddress: cart.billingAddress } : {}),
      ...(cart?.shippingAddress ? { deliveryAddress: cart.shippingAddress } : {}),
      ...(cart?.customerDetails ? { customer: cart.customerDetails } : {}),
    },
    shippingFormFields: {
      billingAddress: [
        'additionalAddressInfo',
        'city',
        'firstName',
        'lastName',
        'postalCode',
        'streetName',
      ],
      contactDetails: ['email', 'phone'],
    },
    shippingMethodsLogos: shippingMethodsLogos ? shippingMethodsLogos : {},
    storeFinder: {},
    ...(orderTimeOut ? { orderCreationTimeOutLimit: orderTimeOut } : {}),
    features: {
      isCompanyEnabled: false,
      isServiceStoreEnabled: false,
    },
    paymentMethodLogos,
    login: {
      disableSignup: true,
      returnPath: cart ? `${routes.shipping?.path}?cartId=${cart.id}` : routes.shipping?.path,
    },
    routes,
    payments: {
      applePay: {
        merchantName: 'Smarteyes',
      },
    },
    logoVariant: 'main',
  }
}
