import { Countries, Currencies, DistanceUnit, IntlSettings, Locales } from '@grandvisionhq/state'

export const INTL_DEFAULTS: IntlSettings = {
  country: Countries.SE,
  locale: Locales['sv-SE'],
  currency: Currencies.SEK,
  distanceUnit: DistanceUnit.km,
  dateFormat: {
    short: 'dd/MM/yyyy',
    midsize: 'dd MMMM yyyy',
    long: 'eeee d MMMM, yyyy',
  },
}

type PossibleLocales = keyof Pick<typeof Locales, 'sv-SE'>

export const intlConfigMap: {
  [L in PossibleLocales]: IntlSettings
} = {
  'sv-SE': INTL_DEFAULTS,
}
