export const consents = [
  {
    title: 'appointments.consent.userAgreement',
    value: 'userAgreement',
    required: true,
  },
]

export const optIns = [
  {
    title: 'appointments.optIn.marketingEmails',
    value: 'marketingEmails',
  },
]
