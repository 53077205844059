import * as React from 'react'

import getConfig from 'next/config'

import { AuthProvider, Routes, Types } from '@grandvisionhq/auth'

type AuthConfig = React.ComponentProps<typeof AuthProvider>['settings']

const { publicRuntimeConfig } = getConfig()
const apiUrl = publicRuntimeConfig.grandvision.apiUrl ?? ''
const {
  auth0Domain,
  auth0CustomDomain,
  auth0ClientId,
  putPrescriptionTokensOnCart,
  disableAddressEditing,
} = publicRuntimeConfig.grandvision.myAccount ?? {}

const routes: Routes = {
  appointments: 'appointments',
  changePassword: 'change-password',
  createAccount: 'create',
  linkExpired: 'link-expired',
  login: 'login',
  logout: 'logout',
  profile: 'profile',
  resetPassword: 'reset-password',
  setPassword: 'set-password',
  settings: 'settings',
  signup: 'signup',
  unlinkOrder: 'unlink-order',
  verify: 'verify',
  wishlist: 'wishlist',
}

const supportFeaturesLinks: Types.SupportFeatureLink[] = [
  {
    label: 'account.menu.appointments',
    url: routes.appointments,
  },
  {
    label: 'account.menu.settings',
    url: routes.settings,
  },
]

const optIns: Types.OptInItem[] = [
  {
    value: 'consent-use-of-data',
    titleKey: 'account.optIn.useOfData',
    tooltipKey: 'optIn.useOfData.tooltip',
  },
  {
    value: 'direct-mail',
    titleKey: 'account.optIn.directMail',
    tooltipKey: 'account.optIn.directMail.tooltip',
    isInSignup: true,
  },
  {
    value: 'newsletter',
    titleKey: 'account.optIn.newsletter',
    tooltipKey: 'account.optIn.newsletter.tooltip',
    isInSignup: true,
  },
  {
    titleKey: 'account.optIn.allMarketingComms',
    tooltipKey: 'account.optIn.allMarketingComms.tooltip',
    isInSignup: true,
    groupedOptIns: [
      {
        titleKey: 'account.optIn.marketingEmails',
        tooltipKey: 'account.optIn.marketingEmails.tooltip',
        value: 'EMAIL_MARKETING',
      },
      {
        titleKey: 'account.optIn.phone',
        tooltipKey: 'account.optIn.phone.tooltip',
        value: 'PHONE_MARKETING',
      },
      {
        titleKey: 'account.optIn.mobileMarketing',
        tooltipKey: 'account.optIn.mobileMarketing.tooltip',
        value: 'MOBILE_MARKETING',
      },
      {
        titleKey: 'account.optIn.directMail',
        tooltipKey: 'account.optIn.directMail.tooltip',
        value: 'POSTAL_MARKETING',
      },
      {
        titleKey: 'account.optIn.socialMedia',
        tooltipKey: 'account.optIn.socialMedia.tooltip',
        value: 'SOCIAL_MEDIA_MARKETING',
      },
    ],
  },
]

export const getAuthConfig = (): AuthConfig => ({
  routes,
  optIns,
  apiUrl,
  supportFeaturesLinks,
  putPrescriptionTokensOnCart,
  disableAddressEditing,
  clientId: auth0ClientId,
  customDomain: auth0CustomDomain,
  domain: auth0Domain,
  basePath: 'my-account',
  checkoutRoute: '/checkout/shipping',
})
